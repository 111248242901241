<template>
  <header class="app-header">
    <div class="app-header__content container">
      <h1>
        <img src="avatar.png" class="avatar" />
        <div class="bel">
          <span>Bel</span>
          <span class="offline" v-text="onLine ? 'online' : 'offline'" />
        </div>
        <slot></slot>
      </h1>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    onLine: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-header {
  height: 72px;
  background: var(--color-secondary);
  box-shadow: 0 12px 12px hsla(0, 0%, 0%, 0.3);

  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: var(--color-light);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: unset;

      .avatar {
        width: 4rem;
        height: 4rem;
        padding: 0.4rem;
        margin: 0 1rem;
        background: var(--color-secondary);
        border-radius: 50%;
        transform: scale(1.5) translateY(0.5rem);
        box-shadow: 0 6px 6px 0 hsla(0, 0%, 0%, 0.3);
      }

      span {
        display: inline-block;
        font-family: 'Segoe UI';
        font-size: 1.4rem;
        margin-left: 1ch;
        color: var(--color-bel-light);
      }

      .bel {
        display: flex;
        flex-direction: column;
        padding-top: 1.6rem;

      }

      .bel span {
        font-size: 1.6rem;
        margin: 0 0 0 1rem;
        color: var(--color-bel-light);
        font-family: 'Segoe UI Bold';
      }

      .bel .offline {
        font-size: 0.5em;
        font-family: Roboto, sans-serif !important;
        opacity: 0.7;
      }
    }
  }

  &__logo-tre {
    width: 5.5rem;
    opacity: 0.8;
  }
}
</style>
