<template>
  <transition :name="speaker + '-anime'">
    <div v-if="mostra" class="persona-speaker" :class="{
      'persona-speaker--bel': speaker === 'bel',
      'persona-speaker--eleitor': speaker === 'eleitor',
    }">
      <span class="persona-speaker__raje" v-if="speaker === 'bel'"></span>
      <span class="persona-speaker__eleitor" v-else>Você:</span>
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    speaker: {
      type: String,
      default: 'bel',
    },
  },
  data() {
    return {
      mostra: false,
    }
  },
  mounted() {
    setTimeout(() => (this.mostra = true), 1)
  },
}
</script>

<style lang="scss" scoped>
.persona-speaker {
  display: flex;
  margin-bottom: 1rem;

  &--bel {
    align-self: flex-start;
  }

  &--eleitor {
    align-self: flex-end;
  }

  &__raje,
  &__eleitor {
    font-family: 'Merienda';
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    left: 1.8rem;
    font-size: 1.2rem;
    color: #333;
  }

  &__eleitor {
    left: unset;
    right: 1.6rem;
  }
}
</style>
