<template>
  <section class="basic-card talking-balloon talking-balloon--bel">
    <div class="basic-card__card">
      <img
        :title="message.basicCard.image.accessibilityText"
        :alt="message.basicCard.image.accessibilityText"
        :src="
          message.basicCard.image.imageUri
            ? message.basicCard.image.imageUri
            : message.basicCard.image.url
        "
        v-if="message.basicCard.image"
      />
      <hgroup>
        <h1>{{ message.basicCard.title }}</h1>
        <h2>{{ message.basicCard.subtitle }}</h2>
      </hgroup>
      <section>
        <p>{{ message.basicCard.formattedText }}</p>
        <div v-for="(button, index) in message.basicCard.buttons" :key="index">
          <a
            target="_blank"
            :href="
              button.openUriAction
                ? button.openUriAction.uri
                : button.openUrlAction.url
            "
            >{{ button.title }}
          </a>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
//import TalkingBalloon from "./TalkingBalloon.vue";
export default {
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  components: {
    //TalkingBalloon
  },
}
</script>

<style lang="scss" scoped>
.basic-card {
  &__card {
    border-radius: 0.5rem;
    background: hsla(0, 100%, 100%, 0.3);
    padding: 0.5rem;
  }
  img {
    max-width: 180px;
  }
  h1 {
    font-size: 1.6rem;
    text-transform: capitalize;
  }
  h2,
  p {
    text-transform: capitalize;
    font-size: 1.5rem;
  }
  a {
    color: var(--color-secondary-dark);
    text-decoration: none;
    border-bottom: 3px solid var(--color-secondary-dark);
  }
}
</style>
