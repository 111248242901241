import axios from 'axios'

let urlServidor = (function() {
  return process.env.VUE_APP_ENDPOINT
})()

const instance = axios.create({
  baseURL: urlServidor,
  timeout: 0,
})
console.log('process.env.VUE_APP_ENDPOINT', process.env.VUE_APP_ENDPOINT)
export default instance
