<template>
  <div id="app" style="--bg-hue: var(--mia-hue)">
    <app-header class="header-block" :on-line="online">
      <div>
        <button class="header-block__icon material-icons" @click="mute(true)"
          v-if="muted == false && speechRecognitionSupport">
          volume_up
        </button>
        <button class="header-block__icon material-icons" @click="mute(false)"
          v-if="muted == true && speechRecognitionSupport">
          volume_off
        </button>
      </div>
    </app-header>
    <div class="wrapper-to-talk-block">
      <main class="talk-block scrollable">
        <!-- CHAT BOX -->
        <transition name="bel-anime">
          <persona-speaker speker="bel" v-if="saudacao">
            <talking-balloon speaker="bel" :speakerSaid="saudacao" />
          </persona-speaker>
        </transition>
        <template v-if="talks.length > 0">
          <div v-for="(talk, italk) in talks" :key="italk" style="display: flex; flex-direction: column">
            <transition name="eleitor-anime">
              <persona-speaker speaker="eleitor" v-if="!talk.queryResult">
                <talking-balloon speaker="eleitor" :speakerSaid="talk" />
              </persona-speaker>
            </transition>
            <!-- BLOCO DE RESPOSTA(S) DO RAJE -->
            <template v-if="talk.queryResult && talk.queryResult.fulfillmentMessages">
              <div v-for="(message, imessage) in talk.queryResult
                .fulfillmentMessages" :key="imessage">
                <template v-if="message.text">
                  <persona-speaker speaker="bel" v-for="(text, itext) in message.text.text" :key="itext">
                    <talking-balloon speaker="bel" :speakerSaid="text.linkify()" />
                  </persona-speaker>
                </template>

                <persona-speaker v-if="message.simpleResponse">
                  <talking-balloon speaker="bel" v-if="!message.simpleResponse.displayText ||
                    message.simpleResponse.displayText == ''
                  " :speakerSaid="message.simpleResponse.textToSpeech.linkify()" />
                  <talking-balloon speaker="bel" v-else :speakerSaid="message.simpleResponse.displayText.linkify()" />
                </persona-speaker>

                <template v-if="message.simpleResponses &&
                  message.simpleResponses.simpleResponses
                ">
                  <persona-speaker speaker="bel" v-for="(text, itext) in message.simpleResponses
                    .simpleResponses" :key="itext">
                    <talking-balloon speaker="bel" v-if="!text.displayText || text.displayText == ''"
                      :speakerSaid="text.textToSpeech.linkify()" />
                    <talking-balloon speaker="bel" v-else :speakerSaid="text.displayText.linkify()" />
                  </persona-speaker>
                </template>

                <template v-if="message.suggestions && message.suggestions.suggestions">
                  <div class="wrapper-to-suggestions">
                    <button v-for="(text, itext) in message.suggestions.suggestions"
                      @click="autosubmit($event, text.title)" :key="itext">
                      {{ text.title }}
                    </button>
                  </div>
                </template>

                <template v-if="message.listSelect && message.listSelect.items">
                  <div class="wrapper-to-list">
                    <button v-for="(item, itext) in message.listSelect.items" @click="autosubmit($event, item.info.key)"
                      :key="itext" class="item">
                      {{ item.title }}
                    </button>
                  </div>
                </template>

                <persona-speaker v-if="message.basicCard" speaker="bel">
                  <basic-card :message="message" />
                </persona-speaker>
              </div>
            </template>
          </div>
        </template>
        <transition name="bel-anime-typing">
          <persona-speaker speaker="bel" v-if="rajeTyping && online">
            <talking-balloon speaker="bel" :speakerSaid="typing" />
          </persona-speaker>
        </transition>
        <transition name="bel-anime">
          <!-- SEM INTERNET -->
          <persona-speaker speker="bel" v-if="!online">
            <talking-balloon speaker="bel" :speakerSaid="`<p>Ops!</p><p>Parece que sua conexão com a internet caiu.</p>`
              " />
          </persona-speaker>
        </transition>
        <transition name="eleitor-anime">
          <template v-if="endOfConversation">
            <div class="wrapper-to-suggestions">
              <button @click="habitaConversacao">
                Reiniciar conversação
              </button>
            </div>
          </template>
        </transition>
        <div id="scroll"></div>
      </main>
    </div>
    <!-- INPUT -->
    <div class="wrapper-to-input-block" v-if="micro == false">
      <section class="container input-block">
        <!--https://github.com/vuejs/vue/issues/8231 - v-model on mobile not updating until a space is pressed-->
        <input class="input-block__input" :aria-label="config.locale.strings.inputDoEleitor" autocomplete="off"
          :value="query" @input="onInputQuery" @keyup.enter="submit()"
          :placeholder="config.locale.strings.inputDoEleitor" autofocus type="text" id="input-query-text"
          ref="input-query-text" :disabled="endOfConversation" />
        <button class="input-block__icon material-icons" @click="microphone(true)" :disabled="endOfConversation"
          v-if="query.length == 0 && speechRecognitionSupport">
          mic
        </button>
        <button tabindex="0" :disabled="endOfConversation" aria-label="Enviar" class="input-block__icon material-icons"
          @click="submit" v-if="query.length > 0 || !speechRecognitionSupport">
          send
        </button>
      </section>
    </div>
    <div class="wrapper-to-input-block" v-else>
      <section class="container input-block">
        <input class="input-block__input" :disabled="endOfConversation" :placeholder="speech" readonly />
        <button :disabled="endOfConversation" class="input-block__icon material-icons recording"
          @click="microphone(false)">
          mic
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import axios from '@/services/api/WSChatbotConfig'
import * as DOMPurify from 'dompurify'
import uuidv4 from 'uuid/v4'
import AppHeader from './components/AppHeader.vue'
import BasicCard from './components/BasicCard.vue'
import PersonaSpeaker from './components/PersonaSpeaker.vue'
import TalkingBalloon from './components/TalkingBalloon.vue'
//const { struct } = require('pb-util')
const sessionId = uuidv4() // Random Session ID

if (!String.linkify) {
  String.prototype.linkify = function () {
    // http://, https://, ftp://
    var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim

    // www. sans http:// or https://
    var pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim

    // Email addresses
    var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim

    return this.replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
      .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>')
  }
}

const config = {
  app: {
    muted: false, // <- mute microphone by default
    googleIt: true, // <- ask users to google their request, in case of input.unknown action
  },
  locale: {
    strings: {
      saudacaoDoRaje: `<p>Olá!</p><p>Me chamo Bel, sou a assistente virtual do TRE-MG e estou aqui para te ajudar!</p><p>Logo abaixo, temos alguns assuntos que podem responder as suas dúvidas e, caso deseje voltar ao início, é só digitar 'MENU'</p>|
      <p>Tudo bem?</p>Eu sou a Bel, assistente virtual do TRE-MG. Logo abaixo, alguns assuntos abordados podem te auxiliar. Caso deseje voltar ao início, digite 'MENU'</p>|
      <p>"Oi! Meu nome é Bel, a assistente virtual do TRE-MG e estou aqui para te auxiliar nos assuntos da justiça eleitoral.</p><p>Você pode clicar e se informar melhor sobre os assuntos abaixo. Para voltar ao início, digite 'MENU'</p>`,
      inputDoEleitor: 'Digite uma mensagem...',
      voiceTitle: 'Pode falar, estou ouvindo...',
    },
    settings: {
      speechLang: 'pt-BR', // <- output language
      recognitionLang: 'pt-BR', // <- input(recognition) language
    },
  },
}

const App = {
  components: {
    AppHeader,
    PersonaSpeaker,
    TalkingBalloon,
    BasicCard,
  },
  data() {
    return {
      talks: [],
      saudacao: null,
      query: '',
      rajeTyping: false,
      typing: `<div class="dot-elastic" style="margin: 1rem 1.6rem"></div>`,
      recognition: null,
      speech: config.locale.strings.voiceTitle,
      micro: false,
      muted: config.app.muted,
      online: navigator.onLine,
      config,
      teste: `<p>teste</p>`,
      speechRecognitionSupport: 'webkitSpeechRecognition' in window,
      voices: [],
      endOfConversation: false,
    }
  },
  watch: {
    talks: function () {
      setTimeout(() => {
        document.querySelector('#scroll').scrollIntoView({
          behavior: 'smooth',
        })
      }, 100)
    },
  },
  methods: {
    submit(askingByVoice) {
      let ctx = this
      this.query = this.replaceTagsCaracteres(this.query)
      if (this.query === '' || !this.online) {
        this.rajeTyping = false
        return
      }
      this.interruptVoice()

      const queryText = this.query
      this.talks.push(this.query.slice(0, this.query.length))

      this.query = ''
      this.speech = config.locale.strings.voiceTitle // <- reset query and speech
      this.rajeTyping = true
      this.limpaSugestoes()

      axios
        .post('/detect-intent', {
          sessionId,
          queryText,
        })
        .then(response => {
          this.rajeTyping = false
          response = response.data // Get Actual Data
          var payload
          if (response.queryResult.webhookPayload != null) {
            //payload = struct.decode(response.queryResult.webhookPayload)
            payload = response.queryResult.webhookPayload
          }

          if (
            payload &&
            payload.google &&
            payload.google.richResponse &&
            payload.google.richResponse.items.length > 0
          ) {
            response.queryResult.fulfillmentMessages = []
            if (payload.google.richResponse.items) {
              for (
                var i = 0;
                i < payload.google.richResponse.items.length;
                i++
              ) {
                response.queryResult.fulfillmentMessages.push(
                  payload.google.richResponse.items[i]
                )
              }
            }
            if (payload.google.richResponse.suggestions) {
              var sugestoes = {
                message: 'suggestions',
                suggestions: { suggestions: [] },
              }
              for (
                let i = 0;
                i < payload.google.richResponse.suggestions.length;
                i++
              ) {
                sugestoes.suggestions.suggestions.push(
                  payload.google.richResponse.suggestions[i]
                )
              }
              response.queryResult.fulfillmentMessages.push(sugestoes)
            }
          }

          if (
            response.webhookStatus != null &&
            response.webhookStatus.code > 0
          ) {
            response.queryResult.fulfillmentMessages = []
            response.queryResult.fulfillmentMessages.push({
              simpleResponse: {
                textToSpeech: 'Os serviços de consulta estão indisponíveis.',
                displayText:
                  '<p>Ops! Me desculpe.</p><p>Neste momento os serviços de consulta estão indisponíveis.</p><p>Por favor, tente novamente mais tarde.</p>',
              },
            })
          }

          //se possuir mensagens nas duas plataformas, usa apenas a plataforma ACTIONS_ON_GOOGLE para evitar duplicação
          if (
            response.queryResult.fulfillmentMessages.filter(
              m => m.platform === 'ACTIONS_ON_GOOGLE'
            ).length > 0 &&
            response.queryResult.fulfillmentMessages.filter(
              m => m.platform === 'PLATFORM_UNSPECIFIED'
            ).length > 0
          ) {
            response.queryResult.fulfillmentMessages = response.queryResult.fulfillmentMessages.filter(
              m => m.platform === 'ACTIONS_ON_GOOGLE'
            )
          }
          setTimeout(() => this.talks.push(response), 100)
          this.handle(response, askingByVoice)
          this.detectaFimDeConversacao(response)
        })
        .catch(function (error) {
          console.log(error)
          let handleError = { queryResult: { fulfillmentMessages: [] } }
          if (error.response && error.response.status == 429) {
            handleError.queryResult.fulfillmentMessages.push({
              simpleResponse: {
                textToSpeech: '',
                displayText:
                  '<p>Estou recebendo muitas solicitações no momento.</p><p>Envie novamente sua última solicitação daqui a alguns instantes.</p>',
              },
            })
          } else {
            handleError.queryResult.fulfillmentMessages.push({
              simpleResponse: {
                textToSpeech: '',
                displayText:
                  '<p>Ops! Me desculpe. Estou em manutenção.</p><p>Por favor, tente novamente mais tarde.</p>',
              },
            })
          }
          ctx.rajeTyping = false
          setTimeout(() => ctx.talks.push(handleError), 100)
        })
    },
    autosubmit(ev, suggestion, askingByVoice) {
      if (suggestion === config.locale.strings.voiceTitle) {
        return
      }
      this.query = suggestion
      this.limpaSugestoes()
      this.submit(askingByVoice)
    },
    limpaSugestoes() {
      const _sugestoesEl = Array.from(
        document.querySelectorAll('.wrapper-to-suggestions')
      )
      _sugestoesEl.map(item => (item.innerHTML = ''))
    },
    mute(mode) {
      this.muted = mode
      if (mode) {
        this.interruptVoice()
      }
    },
    interruptVoice() {
      if (typeof speechSynthesis !== 'undefined') {
        window.speechSynthesis.cancel()
      }
    },
    microphone(mode) {
      this.micro = mode
      let self = this // <- correct scope

      if (mode == true) {
        this.interruptVoice()
        this.recognition = new window.webkitSpeechRecognition() // <- chrome speech recognition

        navigator.permissions
          .query({ name: 'microphone' })
          .then(function (permissionStatus) {
            if (permissionStatus.state === 'denied') {
              self.micro = false
              return
            }
            permissionStatus.onchange = function () {
              if (permissionStatus.state === 'denied') {
                self.micro = false
                return
              }
            }
          })

        this.recognition.interimResults = true
        this.recognition.lang = config.locale.settings.recognitionLang
        this.recognition.start()

        this.recognition.onresult = function (event) {
          self.speech = ''
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            let transcript = event.results[i][0].transcript
            if (event.results[i].isFinal) {
              self.speech = transcript
            } else {
              self.speech += transcript
            }
          }
        }

        this.recognition.onend = function () {
          self.recognition.stop()
          self.micro = false
          self.autosubmit(event, self.speech, true)
        }
      } else {
        if (this.recognition != null) {
          this.recognition.stop()
        }
      }
    },
    handle(response, askingByVoice) {
      if (askingByVoice && this.muted == false) {
        let messagesToSpeech = []
        for (let message of response.queryResult.fulfillmentMessages) {
          if (message.simpleResponse) {
            if (
              message.simpleResponse.displayText &&
              message.simpleResponse.textToSpeech != ''
            ) {
              messagesToSpeech.push(message.simpleResponse.textToSpeech)
            }
          }
          if (
            message.simpleResponses &&
            message.simpleResponses.simpleResponses
          ) {
            for (let simpleResponse of message.simpleResponses
              .simpleResponses) {
              if (
                simpleResponse.displayText &&
                simpleResponse.textToSpeech != ''
              ) {
                messagesToSpeech.push(simpleResponse.textToSpeech)
              }
            }
          }
        }
        let voice = null
        let googleVoices = this.voices.filter(
          v => v.voiceURI === 'Google português do Brasil'
        )
        if (googleVoices.length > 0) {
          voice = googleVoices[0]
        }
        for (let textToSpeech of messagesToSpeech) {
          let speech = new SpeechSynthesisUtterance(textToSpeech)
          if (voice != null) {
            speech.voice = voice
          }
          speech.lang = config.locale.settings.speechLang

          window.speechSynthesis.speak(speech) // <- Speech output if microphone is allowed
          let r = setInterval(() => {
            if (!window.speechSynthesis.speaking) {
              clearInterval(r)
            } else {
              window.speechSynthesis.resume()
            }
          }, 14000)
        }
      }
    },
    detectaFimDeConversacao(response) {
      if (response?.queryResult?.diagnosticInfo?.end_conversation) {
        this.endOfConversation = true
      } else {
        this.endOfConversation = false
      }
    },
    habitaConversacao() {
      this.endOfConversation = false
    },
    populateVoiceList() {
      if (typeof speechSynthesis === 'undefined') {
        return
      }

      this.voices = speechSynthesis.getVoices()
    },
    onInputQuery(e) {
      this.query = DOMPurify.sanitize(e.target.value)
    },
    replaceTagCaractere(tag) {
      const tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
      }
      return tagsToReplace[tag] || tag
    },
    replaceTagsCaracteres(str) {
      return str.replace(/[&<>]/g, this.replaceTagCaractere)
    },
  },
  mounted() {
    this.populateVoiceList()
    if (
      typeof speechSynthesis !== 'undefined' &&
      speechSynthesis.onvoiceschanged !== undefined
    ) {
      speechSynthesis.onvoiceschanged = this.populateVoiceList
    }
    const saudacoes = config.locale.strings.saudacaoDoRaje.split('|')
    const posicao = Math.floor(Math.random() * Math.floor(saudacoes.length + 1))
    this.saudacao = saudacoes[posicao]
    if (!this.saudacao)
      this.saudacao = `<p>Olá!</p><p>Tudo bem?</p><p>Qual a sua dúvida?</p>`
    this.saudacao += ``
    console.log('aqui', this)
    this.autosubmit(undefined, 'menu')
    setInterval(() => (this.online = navigator.onLine), 1000)
  },
}



export default App;
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap');
@import url('https://unpkg.com/material-components-web@0.26.0/dist/material-components-web.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

:root {

  --font-family-primary: 'Segoe UI', Arial, Helvetica, sans-serif;
  font-size: 12px;
  --color-light: #e6ecf0;
  --color-secondary: #1b305a;
  --color-secondary-70: #264480;
  --color-secondary-dark: #222024;
  --color-secondary-dark-30: #28262b;
  --color-primary-light: rgb(39, 223, 255);
  --color-primary-light-70: hsla(157, 94%, 72%, 0.7);
  --color-bel-light: #f3f6f9;
  --color-eleitor-light: #f3f6f9;
}

*,
*::before,
*::after {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body,
#app {
  height: inherit;
}

#app {
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-block {
  flex-grow: 0;
  z-index: 1;
}

.header-block__input:focus,
.header-block__icon:focus {
  outline: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.header-block__icon {
  display: block;
  flex-grow: 0;
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  margin-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 12px 12px hsla(0, 0%, 0%, 0.3);
  background: var(--color-secondary);
  color: var(--color-eleitor-light);
  border-radius: 2.5rem;
}

.wrapper-to-talk-block {
  flex-grow: 1;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.talk-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

@media (min-width: 600px) {
  .talk-block {
    padding-left: calc(calc(100% - 600px) / 2);
    padding-right: calc(calc(100% - 600px) / 2);
  }
}

.wrapper-to-input-block {
  background: var(--color-secondary-70);
  box-shadow: 0 -6px 6px hsla(0, 0%, 0%, 0.1);
}

.input-block {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  width: 100%;
  padding: 1rem;
  //background: var(--color-primary);
  //box-shadow: 0 -6px 12px hsla(0, 0%, 0%, 0.3);
}

.wrapper-to-suggestions {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  >* {
    font-size: 18px;
    margin: 0 0.8rem;
    height: 44px;
    padding: 0 1.6rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 0;
    min-width: 9rem;
    border: 3px solid var(--color-eleitor-light);
    background: var(--color-eleitor-light);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    font-family: var(--font-family-primary);
    transition: all 0.3s ease;
  }

  >*:focus {
    outline: none;
    border: 3px solid var(--color-primary-light);
    transition: all 0.3s ease;
  }
}


.wrapper-to-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;

  >.item {
    font-size: 18px;
    margin: 0 0.8rem;
    font-weight: bold;
    height: 44px;
    padding: 0 1.6rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 0;
    min-width: 9rem;
    border: 3px solid var(--color-eleitor-light);
    background: var(--color-eleitor-light);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    font-family: 'Segoe UI Bold';

    transition: all 0.3s ease;
  }

  >*:focus {
    outline: none;
    border: 3px solid var(--color-primary-light);
    transition: all 0.3s ease;
  }
}

.input-block__input {
  flex-grow: 1;
  height: 5rem;
  line-height: 5rem;
  border-radius: 2.5rem;
  padding: 0 1.6rem;
  transition: all 0.3s ease;
  background: var(--color-eleitor-light);
  font-size: 1.6rem;
  border: 1px solid var(--color-primary-light-70);

  &::placeholder {
    color: #555;
  }
}

.input-block__input:focus,
.input-block__icon:focus {
  outline: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.input-block__icon {
  display: block;
  flex-grow: 0;
  width: 5rem;
  height: 5rem;
  margin: 0 1rem;
  margin-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-eleitor-light);
  color: var(--color-secondary);
  border-radius: 2.5rem;
}

ul {
  list-style-type: disc;
  margin: 0.8rem 0;
}

li {
  margin-left: 2rem;
}

ul li::marker {
  font-size: 100%;
  color: var(--color-secondary);
}

.info {
  display: none;
}

.recording {
  color: #f44336;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.scrollable {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: light dark;
}

.scrollable::-webkit-scrollbar {
  width: 7px;
}

.scrollable::-webkit-scrollbar-thumb {
  background: var(--color-primary-light);
  border-radius: 7px;
}

.scrollable::-webkit-scrollbar-track {
  background: transparent;
}

.bel-anime-enter-active,
.bel-anime-typing-enter-active,
.eleitor-anime-enter-active {
  transition: all 0.6s ease;
}

.bel-anime-leave-active,
.bel-anime-typing-leave-active,
.eleitor-anime-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.bel-anime-typing-leave-active {
  transition: none;
}

.bel-anime-enter,
.bel-anime-typing-enter,
.bel-anime-leave-to,
.bel-anime-typing-leave-to,
.eleitor-anime-enter,
.eleitor-anime-leave-to {
  opacity: 0;
}

.bel-anime-enter,
.bel-anime-leave-to {
  transform: translateY(-1rem);
}

.eleitor-anime-enter,
.eleitor-anime-leave-to {
  transform: translateY(1rem);
}

.dot-elastic,
.dot-elastic::before,
.dot-elastic::after {
  background-color: var(--color-secondary-dark) !important;
}

body {
  margin: 0;
  background-color: whitesmoke;
  // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='450' height='450' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23064e77'/%3E%3Cstop offset='1' stop-color='%230a7dbe'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='0.1' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  // background-attachment: fixed;
  // background-repeat: no-repeat;
  // background-position: top left;
  font-family: --font-family-primary;
  font-size: 1rem;
}

[style*='--bg-'] {
  --mia-hue: 150;
  --mia-color: hsl(150, 50%, 50%);
}

button:disabled,
input:disabled {
  opacity: 0.7;
  cursor: none;
}

#app {
  /* Setting a valid hue (any unitless number) will auto-set all the other values */
  --bg-hue: initial;
  /* Adjust the base saturation and lightness as desired */
  --bg-saturation: 45%;
  --bg-lightness: 50%;

  /* set angles for the tint and shade gradients */
  --bg-tint-angle: -30deg;
  --bg-shade-angle: 60deg;

  /* set amounts for default tint & shade */
  --bg-tint-amount: 20%;
  --bg-shade-amount: 20%;

  /* set indiviidual hues for the tint and shade */
  /* defaults are set by higher-level arguments…
   which alows for both simpler & more customized uses*/
  --bg-tint-hue: var(--bg-hue);
  --bg-shade-hue: var(--bg-hue);

  /* set the gradient positions all at once */
  --bg-gradient-stops: 30%;

  /* override tint/shade gradients directly */
  --bg-tint-start: var(--bg-gradient-stops);
  --bg-tint-end: var(--bg-tint-start);
  --bg-shade-start: var(--bg-gradient-stops);
  --bg-shade-end: var(--bg-shade-start);

  /* override tint/shade saturation & lightness directly */
  --bg-tint-saturation: var(--bg-saturation);
  --bg-tint-lightness: calc(var(--bg-lightness) + var(--bg-tint-amount));
  --bg-shade-saturation: var(--bg-saturation);
  --bg-shade-lightness: calc(var(--bg-lightness) - var(--bg-shade-amount));

  /* or override any individual color directly */
  --bg-color: hsl(var(--bg-hue), var(--bg-saturation), var(--bg-lightness));
  --bg-tint: hsla(var(--bg-tint-hue),
      var(--bg-tint-saturation),
      var(--bg-tint-lightness),
      0.25);
  --bg-shade: hsla(var(--bg-shade-hue),
      var(--bg-shade-saturation),
      var(--bg-shade-lightness),
      0.25);

  /* this is the internal logic that creates your angled tint/shade background */
  --bg-image: linear-gradient(var(--bg-tint-angle),
      var(--bg-tint) var(--bg-tint-start),
      transparent var(--bg-tint-end)),
    linear-gradient(var(--bg-shade-angle),
      var(--bg-shade) var(--bg-shade-start),
      transparent var(--bg-shade-end));

  /* Creating a final "output" variable acts like a function return */
  --bg: var(--bg-image) #518787;

  /* Applying that value to a property creates a mixin */
  /* Since the initial return is invalid, nothing happens unless we set a --bg-hue */
  background: url('/fundo.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  ;
}
</style>
