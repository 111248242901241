import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import 'three-dots/sass/three-dots.scss'
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
