<template>
  <div
    v-if="speaker === 'bel'"
    class="talking-balloon talking-balloon--bel"
    v-html="speakerSaid"
  />
  <div
    v-else
    class="talking-balloon talking-balloon--eleitor"
    v-html="speakerSaid"
  />
</template>

<script>
export default {
  props: {
    speaker: {
      type: String,
      default: 'bel',
    },
    speakerSaid: {
      type: [Object, String],
      default: null,
    },
  },
}
</script>

<style lang="scss">
.talking-balloon {
  position: relative;
  margin: 1rem 0;
  margin-top: 0;
  padding: 0.8rem;
  padding-top: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.3);
  font-size: 1.6rem;
  word-break: break-word;
  &--bel {
    align-self: flex-start;
    background: var(--color-bel-light);
    margin-left: 1rem;
    margin-right: 1rem;
    border-top-left-radius: 0;
    min-width: 5ch;
  }
  &--eleitor {
    align-self: flex-end;
    background: var(--color-eleitor-light);
    margin-left: 1rem;
    margin-right: 1rem;
    border-bottom-right-radius: 0;
    text-align: right;
    min-width: 5ch;
  }
  &--bel::before,
  &--eleitor::after {
    content: '';
    position: absolute;
    top: -1rem;
    width: 1rem;
    height: 1rem;
    border-style: solid;
  }
  &--bel::before {
    left: 0rem;
    border-width: 0 1.5rem 1rem 0;
    border-color: transparent transparent var(--color-bel-light) transparent;
  }
  &--eleitor::after {
    top: unset;
    border-width: 0 1.5rem 1rem 0;
    border-color: transparent var(--color-eleitor-light) transparent transparent;
    bottom: -1rem;
    right: 0;
  }
}
</style>
